import { useParams } from "react-router-dom"
import { useState, useEffect} from "react"

import Gallery from "./Gallery";

import PhotoDetails from "./PhotoDetails";

import PhotoStyles from "./Photo.module.css"

export default function Photo() {

    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState('')  
    const [userPhoto, setUserPhoto] = useState({});   
    const [username, setUsername] = useState(''); 

    let {photo} = useParams();

    useEffect(() => {
        console.log("Load photo: ", photo)

        const data = {
            "AddGlobalFeedBool": false,
            "CommentLimit": 20,
            "CommentOffset": 0,
            "FetchParents": false,
            "PostHashHex": photo,
            "ReaderPublicKeyBase58Check": ""            
        }
      
        const url = 'https://api.desodev.com/api/v0/get-single-post'
      
        const params = {
            method:'POST',
            body:JSON.stringify(data),
            headers: { 'Content-Type': 'application/json'}
        }
      
        // load user data
        fetch(url, params)
        .then(res => res.json())
        .then(
            (result) => {
                setIsLoaded(true);               

                if(result.error){
                    setError(result.error);
                } else {
                    setError('')
                    console.log("result: ", result)
                    if(result.PostFound){

                        if(result.PostFound.ProfileEntryResponse && result.PostFound.ProfileEntryResponse.Username){
                            setUsername(result.PostFound.ProfileEntryResponse.Username)
                        }

                        setUserPhoto(result.PostFound)
                    }
                }
            },
            (error) => {
                setIsLoaded(true);
                setError("Failed to load data...");
            }
        )

    }, [photo])      

    return(       
        <div className={PhotoStyles.container}>
            {
                error 
                ? <div>{error}</div>
                : 
                <>
                    {
                        isLoaded 
                        ? 
                        <div>
                            {
                                userPhoto && userPhoto.ImageURLs &&
                                <>
                                    <div className={PhotoStyles.photoContainer}>
                                        <div className={PhotoStyles.photo}>
                                        <div className={PhotoStyles.photoContent}>
                                            <div className={PhotoStyles.image} style={{ 
                                                    backgroundImage: `url(${userPhoto.ImageURLs[0]})`
                                                }}>

                                            </div>   
                                        </div>
                                        </div>
                                        <div className={PhotoStyles.detailsContainer}>
                                            <PhotoDetails photo={userPhoto}/>
                                        </div>
                                    </div>
                                    <hr></hr>
                                </>
                            }
                            {
                                username && 
                                <div>
                                    <div className={PhotoStyles.message}>More photos from  <strong> <a href={`/${username}`}>{username}</a></strong></div>
                                    <Gallery user={username} limit={6}/>
                                </div>
                            }                            
                        </div>
                        : <div>Loading...</div>
                    }                
                </>
            }
        </div>
    )
}
import { useState, useEffect} from "react"
import StatsStyles from "./Stats.module.css"

export default function Stats({user}) {

    const [followersIsLoaded, setFollowersIsLoaded] = useState(false);
    const [errorFollowers, setErrorFollowers] = useState('')     
    const [followers, setFollowers] = useState(undefined);   

    const [followsIsLoaded, setFollowsIsLoaded] = useState(false);
    const [errorFollows, setErrorFollows] = useState('')    
    const [follows, setFollows] = useState(undefined);
   

    useEffect(() => {
        const updateFollowers = () => {
            console.log("Update followers")
    
            const data = {
                "GetEntriesFollowingUsername": true,
                "LastPublicKeyBase58Check": "", 
                "NumToFetch": 0,
                "PublicKeyBase58Check": "",
                "Username": user,           
            }
          
            const url = 'https://api.desodev.com/api/v0/get-follows-stateless'
          
            const params = {
                method:'POST',
                body:JSON.stringify(data),
                headers: { 'Content-Type': 'application/json'}
            }
          
            fetch(url, params)
            .then(res => res.json())
            .then(
                (result) => {
                    setFollowersIsLoaded(true);               
    
                    if(result.error){
                        setErrorFollowers(result.error);
                    } else {
                        setErrorFollowers('')
                        if(result.NumFollowers){
                            let {NumFollowers} = result
                            setFollowers(NumFollowers)
                        }
                    }
                },
                (error) => {
                    setFollowersIsLoaded(true);
                    setErrorFollowers("Failed to load data...");
                }
            )        
    
        }
    
        const updateFollows = () => {
            console.log("Update follows")
    
            const data = {
                "GetEntriesFollowingUsername": false,
                "LastPublicKeyBase58Check": "", 
                "NumToFetch": 0,
                "PublicKeyBase58Check": "",
                "Username": user,           
            }
          
            const url = 'https://api.desodev.com/api/v0/get-follows-stateless'
          
            const params = {
                method:'POST',
                body:JSON.stringify(data),
                headers: { 'Content-Type': 'application/json'}
            }
          
            fetch(url, params)
            .then(res => res.json())
            .then(
                (result) => {
                    setFollowsIsLoaded(true);               
    
                    if(result.error){
                        setErrorFollows(result.error);
                    } else {
                        setErrorFollows('')
                        if(result.NumFollowers){
                            let {NumFollowers} = result
                            setFollows(NumFollowers)
                        }
                    }
                },
                (error) => {
                    setFollowsIsLoaded(true);
                    setErrorFollows("Failed to load data...");
                }
            )           
        } 

        updateFollowers()
        updateFollows()
    }, [user])      

    return(       
        <div className={StatsStyles.container}>
            
            {
                !errorFollowers && followersIsLoaded && followers && <div><strong>{followers}</strong> followers</div>
            }

            {
                !errorFollows && followsIsLoaded && follows && <div><strong>{follows}</strong> following</div>
            }

        </div>
    )
}
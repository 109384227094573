import { useState, useEffect} from "react"
import GalleryStyles from "./Gallery.module.css"

export default function Gallery({user, limit}) {

    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState('') 
    const [photos, setPhotos] = useState([]);
    //const [lastPostHash, setLastPostHash] = useState('');

    useEffect(() => {

        console.log("load posts for: ", user)

        const data = {
            "LastPostHashHex": '', 
            "NumToFetch": 200, 
            "PublicKeyBase58Check": "", 
            "ReaderPublicKeyBase58Check": "", 
            "Username": user,
            "MediaRequired": true             
        }
      
        const url = 'https://api.desodev.com/api/v0/get-posts-for-public-key'
      
        const params = {
            method:'POST',
            body:JSON.stringify(data),
            headers: { 'Content-Type': 'application/json'}
        }
      
        fetch(url, params)
        .then(res => res.json())
        .then(
            (result) => {
                setIsLoaded(true);               
                //console.log("result: ", result)

                if(result.error){
                    setError(result.error);
                    //console.log(result.error)
                } else {
                    setError('')
                    if(result.Posts){
                        //setUserProfile(result.Profile)
                        // let {LastPostHashHex, Posts} = result
                        let {Posts} = result
                        //setLastPostHash(LastPostHashHex)
                        //console.log("Posts: ", Posts)
                        //console.log("Number of posts with media: ", Posts.length)

                        // Array.isArray() also maybe check if value is array
                        let imagePosts = Posts.filter(post => post.ImageURLs !== null && post.ImageURLs.length !== 0 && post.ImageURLs[0] !== '');
                        //console.log("imagePosts: ", imagePosts)

                        // if there is limit, reduce array to this limit
                        if(limit){
                            imagePosts = imagePosts.slice(0, limit);
                        }

                        setPhotos(imagePosts)

                        //console.log("Number of posts with photos: ", imagePosts.length)

                        //console.log("imagePosts: ", imagePosts)
                    } else{
                        setPhotos([])
                    }
                }
            },
            (error) => {
                setIsLoaded(true);
                setError("Failed to load data...");
            }
        )

    }, [user, limit])   

    return(       
        <div className={GalleryStyles.container}>
            {
                error 
                ? <div>{error}</div>
                : 
                <>
                    {
                        isLoaded 
                        ? 
                        <div className={GalleryStyles.gallery}>
                            {photos && photos.map(({ImageURLs, PostHashHex}, index) =>
                                <a href={`/p/${PostHashHex}`} key={index} className={GalleryStyles.photoContainer}>

                                    <div className={GalleryStyles.photo} style={{ 
                                        backgroundImage: `url(${ImageURLs[0]})`
                                    }}>    
                                    </div>

                                </a>
                            )}                              
                        </div>
                        : <div>Loading...</div>
                    }                
                </>
            }
        </div>
    )
}
import { useState, useEffect} from "react"
import { Link } from "react-router-dom";

import PageContent from './components/PageContent'

import './App.css';

function App() {

  const [query, setQuery] = useState('')
  const [isLoaded, setIsLoaded] = useState(false);
  const [results, setResults] = useState([])  
  const [error, setError] = useState('')  
  const [showResults, setShowResults] = useState(false)

  // identity 
  //window.addEventListener("message", (event) => this.handleMessage(event));

  // initialize
  let userInputValue = query

  // close results window if clicked outside
  useEffect(() => {
      const clickHandler = ({ target }) => {

          const container = document.getElementById('results')
          
          if (container && (container.contains(target) || target.getAttribute("id") === 'search')) {
            // do nothing
            setShowResults(true)
          } else {
            setShowResults(false)
            setQuery('')
          }          
      }

      document.addEventListener("click", clickHandler)

      // these functions clean up the event listeners
      return () => document.removeEventListener("click", clickHandler)
  })   

  useEffect(() => {

    const search = () => {

      //console.log("query: ", query)
  
      // fetch(`https://api-staging.searchclout.net/search/?q=${query}`)
      //   .then(res => res.json())
      //   .then(
      //     (result) => {
      //       setIsLoaded(true);          
      //       setResults(result.posts);
      //     },
      //     (error) => {
      //       setIsLoaded(true);
      //       setError(error);
      //     }
      //   )
  
  
  
      const data = {
        "UsernamePrefix": userInputValue.trim().replace(/^@/, "")   
      }
  
      const url = 'https://api.desodev.com/api/v0/get-profiles'
  
      const params = {
        method:'POST',
        body:JSON.stringify(data),
        headers: { 'Content-Type': 'application/json'}
      }
  
      fetch(url, params)
        .then(res => res.json())
        .then(
          (result) => {
            setIsLoaded(true);          
  
            let { ProfilesFound } = result
  
            // make sure this is the request returned for the last user provided value
            if(ProfilesFound.length > 0 && (query === userInputValue)){
              setIsLoaded(true);          
              setResults(ProfilesFound);
              setShowResults(true)
            } else {
              // do nothing
              // setResults([])
              // setShowResults(false)
            }          
  
          },
          (error) => {
            setIsLoaded(true);
            setError("Failed to load data...");
          }
        )
  
  
      // const res = await fetch(
      //     url,
      //     {
      //         method:'POST',
      //         body:JSON.stringify(data),
      //         headers: { 'Content-Type': 'application/json'}
      //     }
      // );    
  
      // const json = await res.json();
  
      // let { ProfilesFound } = json
  
      // console.log("ProfilesFound: ", ProfilesFound)
  
      // if(ProfilesFound.length > 0){
      //   setIsLoaded(true);          
      //   setResults(ProfilesFound);
      //   setShowResults(true)
      // } else {
      //   setResults([])
      //   setShowResults(false)
      // }
  
    }   


    if(userInputValue){
      search()
    }
  },[userInputValue, query])

  const handleInput = (userInput) => {
    setQuery(userInput) 
    userInputValue = userInput
  } 
 
  let shortDescription = (description) => {
    if(description.length > 60){
      return description.substr(0,60)+'...'
    } else {
      return description
    }
  }
    
  return (
    <div className="App">

      <header className="header">
        <div className="header-wrapper wrapper">  
          <div className="search-container">
          {/* search(e.target.value) */}
          {/* onChange={e => setQuery(e.target.value)} */}
          {/* onChange={e => setQuery(e.target.value)} */}
          {/* onKeyPress={e => onKeyUp(e)} */}
            <input id="search" type="text" autoComplete="off"  placeholder="Search" value={query} onChange={e => handleInput(e.target.value)} /> 
          </div>
          { 
            showResults &&
            <div id="results" className="search-results">       
              <div className="search-results-arrow"></div>       
              <div className="search-results-content">
                {isLoaded && !error && results && results.map(({Username, PublicKeyBase58Check, Description}, index) =>
                  <Link key={index} to={`/${Username}`} className="user-container" onClick={() => setShowResults(false)}>

                    <div className="user-logo" style={{ 
                      height: '50px',
                      width: '50px',
                      backgroundSize: 'cover',
                      backgroundImage: `url(https://bitclout.com/api/v0/get-single-profile-picture/${PublicKeyBase58Check}?fallback=https://bitclout.com/assets/img/default_profile_pic.png)`
                    }}>    
                    </div>

                    <div className='user-details'>
                      <div className='user-name'>{Username}</div>
                      <div className='user-description'>{shortDescription(Description)}</div>
                    </div>

                  </Link>
                )}                
              </div>
              <div className='search-results-shadow'></div> 
            </div>
          }
        </div>
      </header>

      <div className="container wrapper">
          <PageContent />
      </div>

    </div>
  );
}

export default App;

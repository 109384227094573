import { Link } from "react-router-dom";

import filterXSS from 'xss'
import * as _ from "lodash"
import Autolinker from "autolinker";
import twitter from 'twitter-text'

import PhotoDetailsStyles from "./PhotoDetails.module.css"

export default function PhotoDetails({photo}) {

    const sanitizeText = (text) => {
        // filter dangerous stuff
        let updatedText = filterXSS(text)        

        // limit of two newlines in a row
        updatedText = _.replace(text, /\n\n+/g, "\n\n");
    
        // display newlines
        updatedText = _.replace(updatedText, /\n/g, "<br>");    
        
        let entities = twitter.extractEntitiesWithIndices(updatedText, {
            extractUrlsWithoutProtocol: false,
        });
        
        // Only link usernames and cashtags for now (not hashtags etc)
        entities = _.filter(entities, (entity) => entity.screenName || entity.cashtag);
        //updatedText = twitter.autoLink(updatedText);

        let textWithMentionLinks = twitter.autoLinkEntities(updatedText, entities, {
            usernameUrlBase: '/',
            usernameClass: 'deso-username',
            usernameIncludeSymbol: true,
            cashtagUrlBase: '/',
            cashtagClass: 'deso-username',
        });
      
        return Autolinker.link(textWithMentionLinks);
    }    

    let username = photo.ProfileEntryResponse.Username
    let userPublicKey = photo.ProfileEntryResponse.PublicKeyBase58Check
    let likes = photo.LikeCount
    if(likes === 0){
        likes = undefined
    }    
    let diamonds = photo.DiamondCount
    if(diamonds === 0){
        diamonds = undefined
    }
    let date = new Date(Math.round(photo.TimestampNanos/1000000)).toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) + ", " + new Date(Math.round(photo.TimestampNanos/1000000)).toLocaleTimeString('en-GB')   
    
    let text = photo.Body

    return(       
        <div className={PhotoDetailsStyles.container}>
            <div className={PhotoDetailsStyles.header}>
                <div className={PhotoDetailsStyles.avatarFrame}>
                    <div className={PhotoDetailsStyles.avatarImage} style={{ 
                        backgroundImage: `url(https://bitclout.com/api/v0/get-single-profile-picture/${userPublicKey}?fallback=https://bitclout.com/assets/img/default_profile_pic.png)`
                    }}>
                    </div>
                </div>
                <Link to={`/${username}`} className={PhotoDetailsStyles.username}><strong>{username}</strong></Link>
            </div>
            <div className={PhotoDetailsStyles.main}>
                <div className={PhotoDetailsStyles.comments}>
                   <div dangerouslySetInnerHTML={{__html: sanitizeText(text)}}></div> 
                </div>
                
            </div>
            <div className={PhotoDetailsStyles.footer}>
                <div className={PhotoDetailsStyles.stats}>
                    {
                        likes && <div>{likes} likes</div>
                    }
                    {
                        diamonds && <div>{diamonds} diamonds</div>
                    }
                    
                </div>
                <div className={PhotoDetailsStyles.date}>{date}</div>
            </div>
        </div>
    )
}
import { useParams } from "react-router-dom"
import { useState, useEffect} from "react"

import filterXSS from 'xss'
import * as _ from "lodash"
import Autolinker from "autolinker";
import twitter from 'twitter-text'

import Gallery from "./Gallery";
import Stats from "./Stats";

import ProfileStyles from "./Profile.module.css"

export default function Profile() {

    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState('')  
    const [userProfile, setUserProfile] = useState({});

    let {user} = useParams();

    const sanitizeText = (text) => {
        // filter dangerous stuff
        let updatedText = filterXSS(text)        

        // limit of two newlines in a row
        updatedText = _.replace(text, /\n\n+/g, "\n\n");
    
        // display newlines
        updatedText = _.replace(updatedText, /\n/g, "<br>");    
        
        let entities = twitter.extractEntitiesWithIndices(updatedText, {
            extractUrlsWithoutProtocol: false,
        });
        
        // Only link usernames and cashtags for now (not hashtags etc)
        entities = _.filter(entities, (entity) => entity.screenName || entity.cashtag);
        //updatedText = twitter.autoLink(updatedText);

        let textWithMentionLinks = twitter.autoLinkEntities(updatedText, entities, {
            usernameUrlBase: '/',
            usernameClass: 'deso-username',
            usernameIncludeSymbol: true,
            cashtagUrlBase: '/',
            cashtagClass: 'deso-username',
        });
      
        return Autolinker.link(textWithMentionLinks);
    }

    useEffect(() => {
        console.log("load profile for: ", user)

        const data = {
            "Username": user 
        }
      
        const url = 'https://api.desodev.com/api/v0/get-single-profile'
      
        const params = {
            method:'POST',
            body:JSON.stringify(data),
            headers: { 'Content-Type': 'application/json'}
        }
      
        // load user data
        fetch(url, params)
        .then(res => res.json())
        .then(
            (result) => {
                setIsLoaded(true);               

                if(result.error){
                    setError(result.error);
                } else {
                    setError('')
                    if(result.Profile){
                        setUserProfile(result.Profile)
                    }
                }
            },
            (error) => {
                setIsLoaded(true);
                setError("Failed to load data...");
            }
        )

        // can load user gallery same time

        // can load user stats same time also

    }, [user])    

    return(       
        <div className={ProfileStyles.container}>
            {
                error 
                ? <div>{error}</div>
                : 
                <>
                    {
                        isLoaded 
                        ? 
                        <div>
                            <div className={ProfileStyles.userBox}>
                                <div className={ProfileStyles.avatarContainer}>
                                    <div className={ProfileStyles.avatarFrame}>
                                        <div className={ProfileStyles.avatarImage} style={{ 
                                            backgroundImage: `url(https://bitclout.com/api/v0/get-single-profile-picture/${userProfile.PublicKeyBase58Check}?fallback=https://bitclout.com/assets/img/default_profile_pic.png)`
                                        }}></div>
                                    </div>
                                </div>
                                <div className={ProfileStyles.info}>
                                    <div className={ProfileStyles.userName}>
                                        {user}
                                    </div>
                                    <div className={ProfileStyles.userStats}>
                                        <Stats user={user} />
                                    </div>
                                    <div className={ProfileStyles.userDescription} dangerouslySetInnerHTML={{__html: sanitizeText(userProfile.Description)}}></div>
                                </div>                                
                            </div> 
                            <hr></hr>
                            <Gallery user={user} />
                        </div>
                        : <div>Loading...</div>
                    }                
                </>
            }


        </div>
    )
}
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route} from "react-router-dom" 
import './index.css';
import App from './App';

import Profile from './components/Profile'
import Photo from './components/Photo'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} >
          <Route path=":user" element={<Profile />} />
          <Route path="/p/:photo" element={<Photo />} />
          <Route
            path="*"
            element={
              <div style={{ padding: "10px" }}>
                <p>Page Not Found</p>
              </div>
            }
          />          
        </Route>
      </Routes>
    </BrowserRouter>,
  </React.StrictMode>,
  document.getElementById('root')
);


